// 端口配置文件
const port = 80;
const BASEURL = 'https://tools.lyunjian.com/';

const API_LIST = {
	// 登录
	doLogin: BASEURL + 'userhg/Login/doLogin',
	// 首页
	pc_fun: BASEURL + 'apihg/Index/pc_fun',
	// 列表
	pc_list: BASEURL + 'apihg/Index/pc_list',
	// 科室会上传图片
	uploadNew: BASEURL + 'apihg/PcMeet1/uploadNew',
	// 科室会上传文件
	uploadNew2: BASEURL + 'apihg/PcMeet1/uploadNew2',
	// 上传会议流程文件
	upload_process: BASEURL + 'apihg/PcMeet1/upload_process',
	// 删除会议流程文件
	del_process_txt:BASEURL + 'apihg/PcMeet1/del_process_txt',
	// 上传页数据
	pc_submit_page: BASEURL + 'apihg/Index/pc_submit_page',
	// 图片删除
	del_img: BASEURL + 'apihg/Meet1/del_img',
	// 上传ppt
	submit_ppt: BASEURL + 'apihg/PcMeet1/submit_ppt',
	// PC端保存会后总结
	submit_content: BASEURL +'apihg/Meet1/submit_content',
	// PC端删除会后总结文本
	del_content: BASEURL +'apihg/Meet1/del_content',
	// PC端删除会ppt
	del_ppt:BASEURL +'apihg/Meet1/del_ppt',
	// 获取token
	qiniu_token:BASEURL +'apihg/Tokens/qiniu_token',
	// 
	importExecl:BASEURL +'apihg/Pc/importExecl',
	// PC端列表页
	mah_pc_list:BASEURL +'apihg/Index/mah_pc_list',
	// PC端上传页
	uploadPage:BASEURL +'apihg/Mah/uploadPage',
	// 
	uploadfile:BASEURL +'apihg/Mah/uploadfile',
	// 会议名称列表
	memory:BASEURL +'apihg/PcMeet1/memory',
	// 会议列表
	meet1_list:BASEURL +'apihg/PcMeet1/meet1_list',
	// 协议列表
	agreement_list:BASEURL +'apihg/Index/agreement_list',
	// 产品列表
	tg_product_list:BASEURL +'apihg/Visit/tg_product_list',
	// 获取会议通知输入过的内容
	sel_notice_data:BASEURL +'apihg/PcMeet1/sel_notice_data',
	// 目标产品保存与修改
	save_pc_product:BASEURL +'apihg/PcMeet1/save_pc_product',
	// 新建科室会
	add_department:BASEURL +'apihg/PcMeet1/add_department',
	// 医院列表
	sel2:BASEURL +'apihg/PcMeet1/sel2',
	// 添加医院
	add_v_tname: BASEURL +'apihg/Visit/add_v_tname',
	// 科室列表
	sel_depart: BASEURL +'apihg/PcMeet1/sel_depart',
	// 会议目标
	sel_purpose: BASEURL +'apihg/PcMeet1/sel_purpose',
	// 会议主题
	sel_theme: BASEURL +'apihg/PcMeet1/sel_theme',
	// 预算/结算表费用项目列表
	expense_list:BASEURL +'apihg/Meetapi/expense_list',
	// 保存费用项目
	add_expense:BASEURL +'apihg/Meetapi/add_expense',
	// 会议中遇到的问题及解答
	memory_qa:BASEURL +'apihg/PcMeet1/memory_qa',
	// 会后总结会议效果及评价
	memory_se_type1:BASEURL +'apihg/PcMeet1/memory_se_type1',
	// 会后总结会议效果及评价其他人的填写
	u_se_list:BASEURL +'apihg/PcMeet1/u_se_list',
	// 问题和解答其他人
	memery_ed_data:BASEURL +'apihg/PcMeet1/memery_ed_data',
	// 会后跟进效果
	memory_se_type2:BASEURL +'apihg/PcMeet1/memory_se_type2',
	// 点击保存
	save_data:BASEURL +'apihg/PcMeet1/save_data',
	// 会议表单页数据
	update_pcmeet_page1:BASEURL +'apihg/PcMeet1/update_pcmeet_page1',
	// 问题和解答
	update_pcmeet_page2:BASEURL +'apihg/PcMeet1/update_pcmeet_page2',
	// 效果和评级
	update_pcmeet_page3:BASEURL +'apihg/PcMeet1/update_pcmeet_page3',
	// 跟进和计划
	update_pcmeet_page4:BASEURL +'apihg/PcMeet1/update_pcmeet_page4',
	// 获取科室会状态
	de_state:BASEURL +'apihg/PcMeet1/de_state',
	// 删除科室会
	del_department:BASEURL +'apihg/Meet1/del_department',
	// 获取会后总结效果的文件列表
	sel_txt:BASEURL +'apihg/PcMeet1/sel_txt',
	// 贴柜的会议通知
	save_pc_product2:BASEURL +'apihg/PcMeet2/save_pc_product',
	// 药店列表
	sel3:BASEURL +'apihg/PcMeet2/sel2',
	// 获取会议通知输入过的内容
	sel_notice_data2:BASEURL +'apihg/PcMeet2/sel_notice_data',
	// 贴柜点击保存
	save_data2:BASEURL +'apihg/PcMeet2/save_data',
	// 贴柜问题和解答
	update_pcmeet_page2_2:BASEURL +'apihg/PcMeet2/update_pcmeet_page2',
	// 问题和解答其他人
	memery_ed_data_2:BASEURL +'apihg/PcMeet2/memery_ed_data',
	// 贴柜效果和评级
	memory_se_type1_2:BASEURL +'apihg/PcMeet2/memory_se_type1',
	// 贴柜跟进和计划
	update_pcmeet_page4_2:BASEURL +'apihg/PcMeet2/update_pcmeet_page4',
	// 贴柜会后跟进效果
	memory_se_type2_2:BASEURL +'apihg/PcMeet2/memory_se_type2',
	// 科室会上传图片
	uploadNew_2: BASEURL + 'apihg/PcMeet2/uploadNew',
	// 会议列表
	meet1_list_2:BASEURL +'apihg/PcMeet2/meet1_list',
	// 贴柜会议中遇到的问题及解答
	memory_qa_2:BASEURL +'apihg/PcMeet2/memory_qa',
	// 会议列表
	meet1_list_3:BASEURL +'apihg/PcMeet3/meet1_list',
	// 店员的会议通知
	save_pc_product3:BASEURL +'apihg/PcMeet3/save_pc_product',
	// 药店列表
	sel3:BASEURL +'apihg/PcMeet3/sel2',
	// 获取会议通知输入过的内容
	sel_notice_data3:BASEURL +'apihg/PcMeet3/sel_notice_data',
	// 店员点击保存
	save_data3:BASEURL +'apihg/PcMeet3/save_data',
	// 店员会议表单页数据
	update_pcmeet_page1_3:BASEURL +'apihg/PcMeet3/update_pcmeet_page1',
	// 店员问题和解答
	update_pcmeet_page2_3:BASEURL +'apihg/PcMeet3/update_pcmeet_page2',
	// 店员效果和评级
	memory_se_type1_3:BASEURL +'apihg/PcMeet3/memory_se_type1',
	// 问题和解答其他人
	memery_ed_data_3:BASEURL +'apihg/PcMeet3/memery_ed_data',
	// 店员跟进和计划
	update_pcmeet_page4_3:BASEURL +'apihg/PcMeet3/update_pcmeet_page4',
	// 店员会后跟进效果
	memory_se_type2_3:BASEURL +'apihg/PcMeet3/memory_se_type2',
	// 科室会上传图片
	uploadNew_3: BASEURL + 'apihg/PcMeet3/uploadNew',
	// 店员培训新增药店
	add_drugstore: BASEURL + 'apihg/PcMeet3/add_drugstore',
	// 店员上传会议流程文件
	upload_process3: BASEURL + 'apihg/PcMeet3/upload_process',
	// 店员删除会议流程文件
	del_process_txt_3:BASEURL + 'apihg/PcMeet3/del_process_txt',
	// 店员会议中遇到的问题及解答
	memory_qa_3:BASEURL +'apihg/PcMeet3/memory_qa',
	// 店员获取会后总结效果的文件列表
	sel_txt_3:BASEURL +'apihg/PcMeet3/sel_txt',
	// 上传ppt
	submit_ppt3: BASEURL + 'apihg/PcMeet3/submit_ppt',
	
	// 沙龙&研讨会议列表
	meet1_list_4:BASEURL +'apihg/PcMeet4/meet1_list',
	// 沙龙&研讨获取会议通知输入过的内容
	sel_notice_data4:BASEURL +'apihg/PcMeet4/sel_notice_data',
	// 沙龙&研讨的会议通知
	save_pc_product4:BASEURL +'apihg/PcMeet4/save_pc_product',
	// 沙龙会会议主题
	sel_theme4: BASEURL +'apihg/PcMeet4/sel_theme',
	// 沙龙上传会议流程文件
	upload_process4: BASEURL + 'apihg/PcMeet4/upload_process',
	// 店员会议表单页数据
	update_pcmeet_page1_4:BASEURL +'apihg/PcMeet4/update_pcmeet_page1',
	// 沙龙问题和解答
	update_pcmeet_page2_4:BASEURL +'apihg/PcMeet4/update_pcmeet_page2',
	// 沙龙会议中遇到的问题及解答
	memory_qa_4:BASEURL +'apihg/PcMeet4/memory_qa',
	// 沙龙会后总结会议效果及评价
	memory_se_type1_4:BASEURL +'apihg/PcMeet4/memory_se_type1',
	// 沙龙会后总结会议效果及评价其他人的填写
	u_se_list4:BASEURL +'apihg/PcMeet4/u_se_list',
	// 沙龙会问题和解答其他人
	memery_ed_data_4:BASEURL +'apihg/PcMeet4/memery_ed_data',
	// 沙龙会后跟进效果
	memory_se_type2_4:BASEURL +'apihg/PcMeet4/memory_se_type2',
	// 沙龙会上传图片
	uploadNew_4: BASEURL + 'apihg/PcMeet4/uploadNew',
	// 获取沙龙会状态
	de_state4:BASEURL +'apihg/PcMeet4/de_state',
	// 会议目标
	sel_purpose4: BASEURL +'apihg/PcMeet4/sel_purpose',
	// 沙龙获取会后总结效果的文件列表
	sel_txt_4:BASEURL +'apihg/PcMeet4/sel_txt',
	// 沙龙点击保存
	save_data4:BASEURL +'apihg/PcMeet4/save_data',
	// 上传ppt
	submit_ppt4: BASEURL + 'apihg/PcMeet4/submit_ppt',
	
	
	// 学术会议列表
	meet1_list_5:BASEURL +'apihg/PcMeet5/meet1_list',
	// 学术获取会议通知输入过的内容
	sel_notice_data5:BASEURL +'apihg/PcMeet5/sel_notice_data',
	// 学术会议通知
	save_pc_product5:BASEURL +'apihg/PcMeet5/save_pc_product',
	// 学术会会议主题
	sel_theme5: BASEURL +'apihg/PcMeet5/sel_theme',
	// 会议目标
	sel_purpose5: BASEURL +'apihg/PcMeet5/sel_purpose',
	// 学术上传会议流程文件
	upload_process5: BASEURL + 'apihg/PcMeet5/upload_process',
	// 学术会议表单页数据
	update_pcmeet_page1_5:BASEURL +'apihg/PcMeet5/update_pcmeet_page1',
	// 学术问题和解答
	update_pcmeet_page2_5:BASEURL +'apihg/PcMeet5/update_pcmeet_page2',
	// 学术会议中遇到的问题及解答
	memory_qa_5:BASEURL +'apihg/PcMeet5/memory_qa',
	// 学术会后总结会议效果及评价
	memory_se_type1_5:BASEURL +'apihg/PcMeet5/memory_se_type1',
	// 学术会后总结会议效果及评价其他人的填写
	u_se_list5:BASEURL +'apihg/PcMeet5/u_se_list',
	// 问题和解答其他人
	memery_ed_data_5:BASEURL +'apihg/PcMeet5/memery_ed_data',
	// 学术会后跟进效果
	memory_se_type2_5:BASEURL +'apihg/PcMeet5/memory_se_type2',
	// 学术会上传图片
	uploadNew_5: BASEURL + 'apihg/PcMeet5/uploadNew',
	// 获取学术会状态
	de_state5:BASEURL +'apihg/PcMeet5/de_state',
	// 组织单位列表
	science_list5:BASEURL +'apihg/PcMeet5/science_list',
	// 添加组织单位
	add_science_company5:BASEURL +'apihg/PcMeet5/add_science_company',
	// 学术获取会后总结效果的文件列表
	sel_txt_5:BASEURL +'apihg/PcMeet5/sel_txt',
	// 上传ppt
	submit_ppt5: BASEURL + 'apihg/PcMeet5/submit_ppt',
	// 学术点击保存
	save_data5:BASEURL +'apihg/PcMeet5/save_data',
	
	// 产品会议列表
	meet1_list_6:BASEURL +'apihg/PcMeet6/meet1_list',
	// 产品会议获取会议通知输入过的内容
	sel_notice_data6:BASEURL +'apihg/PcMeet6/sel_notice_data',
	// 产品会议通知
	save_pc_product6:BASEURL +'apihg/PcMeet6/save_pc_product',
	// 产品目标
	sel_purpose6: BASEURL +'apihg/PcMeet6/sel_purpose',
	// 产品上传会议流程文件
	upload_process6: BASEURL + 'apihg/PcMeet6/upload_process',
	// 产品会议表单页数据
	update_pcmeet_page1_6:BASEURL +'apihg/PcMeet6/update_pcmeet_page1',
	// 产品问题和解答
	update_pcmeet_page2_6:BASEURL +'apihg/PcMeet6/update_pcmeet_page2',
	// 产品会议中遇到的问题及解答
	memory_qa_6:BASEURL +'apihg/PcMeet6/memory_qa',
	// 产品会后总结会议效果及评价
	memory_se_type1_6:BASEURL +'apihg/PcMeet6/memory_se_type1',
	// 产品会后总结会议效果及评价其他人的填写
	u_se_list6:BASEURL +'apihg/PcMeet6/u_se_list',
	// 产品问题和解答其他人
	memery_ed_data_6:BASEURL +'apihg/PcMeet6/memery_ed_data',
	// 产品会后跟进效果
	memory_se_type2_6:BASEURL +'apihg/PcMeet6/memory_se_type2',
	// 产品会上传图片
	uploadNew_6: BASEURL + 'apihg/PcMeet6/uploadNew',
	// 获取产品会状态
	de_state6:BASEURL +'apihg/PcMeet6/de_state',
	// 产品获取会后总结效果的文件列表
	sel_txt_6:BASEURL +'apihg/PcMeet6/sel_txt',
	// 上传ppt
	submit_ppt6: BASEURL + 'apihg/PcMeet6/submit_ppt',
	// 产品点击保存
	save_data6:BASEURL +'apihg/PcMeet6/save_data',
	// 选择药店
	sel_drugstore6:BASEURL +'apihg/PcMeet6/sel_drugstore',
	// 选择商业
	sel_business6:BASEURL +'apihg/PcMeet6/sel_business',
	// 选择连锁
	sel_chain6:BASEURL +'apihg/PcMeet6/sel_chain',
	// 产品删除会议流程文件
	del_process_txt_6:BASEURL + 'apihg/PcMeet6/del_process_txt',
	// 新增连锁
	add_chain:BASEURL + 'apihg/PcMeet6/add_chain',
	
	
	// 爆破会议列表
	meet1_list_7:BASEURL +'apihg/PcMeet7/meet1_list',
	// 爆破会议获取会议通知输入过的内容
	sel_notice_data7:BASEURL +'apihg/PcMeet7/sel_notice_data',
	// 爆破会议通知
	save_pc_product7:BASEURL +'apihg/PcMeet7/save_pc_product',
	// 爆破目标
	sel_purpose7: BASEURL +'apihg/PcMeet7/sel_purpose',
	// 爆破上传会议流程文件
	upload_process7: BASEURL + 'apihg/PcMeet7/upload_process',
	// 爆破会议表单页数据
	update_pcmeet_page1_7:BASEURL +'apihg/PcMeet7/update_pcmeet_page1',
	// 爆破问题和解答
	update_pcmeet_page2_7:BASEURL +'apihg/PcMeet7/update_pcmeet_page2',
	// 爆破会议中遇到的问题及解答
	memory_qa_7:BASEURL +'apihg/PcMeet7/memory_qa',
	// 爆破会后总结会议效果及评价
	memory_se_type1_7:BASEURL +'apihg/PcMeet7/memory_se_type1',
	// 爆破会后总结会议效果及评价其他人的填写
	u_se_list7:BASEURL +'apihg/PcMeet7/u_se_list',
	// 爆破问题和解答其他人
	memery_ed_data_7:BASEURL +'apihg/PcMeet7/memery_ed_data',
	// 爆破会后跟进效果
	memory_se_type2_7:BASEURL +'apihg/PcMeet7/memory_se_type2',
	// 爆破会上传图片
	uploadNew_7: BASEURL + 'apihg/PcMeet7/uploadNew',
	// 获取爆破会状态
	de_state7:BASEURL +'apihg/PcMeet7/de_state',
	// 爆破获取会后总结效果的文件列表
	sel_txt_7:BASEURL +'apihg/PcMeet7/sel_txt',
	// 上传ppt
	submit_ppt7: BASEURL + 'apihg/PcMeet7/submit_ppt',
	// 爆破点击保存
	save_data7:BASEURL +'apihg/PcMeet7/save_data',
	// 选择药店
	sel_drugstore7:BASEURL +'apihg/PcMeet7/sel_drugstore',
	// 选择商业
	sel_business7:BASEURL +'apihg/PcMeet7/sel_business',
	// 选择连锁
	sel_chain7:BASEURL +'apihg/PcMeet7/sel_chain',
	// 爆破删除会议流程文件
	del_process_txt_7:BASEURL + 'apihg/PcMeet7/del_process_txt',
	// 会议主题
	sel_theme7: BASEURL +'apihg/PcMeet7/sel_theme',
	
	// 促销列表页
	promo_list:BASEURL + 'apihg/Promo/promo_list',
	// 创建促销会
	add_promo:BASEURL + 'apihg/Promo/add_promo',
	// 保存一些实时数据
	save_pc_product8:BASEURL + 'apihg/Promo/save_pc_product',
	// 连锁名称列表
	chain_list:BASEURL + 'apihg/Promo/chain_list',
	// 新增连锁名称
	add_chain:BASEURL + 'apihg/Promo/add_chain',
	// 活动目标列表
	promo_effect:BASEURL + 'apihg/Promo/promo_effect',
	// 任务分解明细上传
	importExecl:BASEURL + 'apihg/Promo/importExecl',
	// 推广方案
	take_plan_list:BASEURL + 'apihg/Promo/take_plan_list',
	// 心得分享
	promo_sum_page:BASEURL + 'apihg/Promo/promo_sum_page', 
	// 保存一些实时数据
	save_data8:BASEURL + 'apihg/Promo/save_data',
	// 会议总结数据
	promo_sum_data:BASEURL + 'apihg/Promo/promo_sum_data',
	// 上传图片
	uploadNew1_8: BASEURL + 'apihg/Promo/uploadNew',
	// 上传文件
	uploadNew_8: BASEURL + 'apihg/Promo/uploadNew2',
	// 活动效果自评
	act_eva_data:BASEURL + 'apihg/Promo/act_eva_data',
	// 下步计划数据
	next_plan_data:BASEURL + 'apihg/Promo/next_plan_data',
	// 促销明细上穿
	importExecl2:BASEURL + 'apihg/Promo/importExecl2',
	// 销售明细保存
	save_data_3:BASEURL + 'apihg/Promo/save_data3',
	// 活动方案页数据
	promo_page1:BASEURL + 'apihg/Promo/promo_page1',
	// 状态
	act_state:BASEURL + 'apihg/Promo/act_state',
	// 活动总结方案数据
	promo_page2:BASEURL + 'apihg/Promo/promo_page2',
	// 获取图片
	imgList:BASEURL + 'apihg/Promo/imgList',
	// 提交单子
	submit_data:BASEURL + 'apihg/Promo/submit_data',
	// 推广方案
	u_se4_list:BASEURL +'apihg/Promo/u_se4_list',
	// 下步计划
	u_se3_list:BASEURL +'apihg/Promo/u_se3_list',
	// 活动效果自评
	u_se2_list:BASEURL +'apihg/Promo/u_se2_list',
	// 会议总结
	u_se1_list:BASEURL +'apihg/Promo/u_se1_list',
	// 删除
	del_promo_act:BASEURL +'apihg/Promo/del_promo_act',
	// 图片删除
	del_img2: BASEURL + 'apihg/Promo/del_img',
}

module.exports = API_LIST;
